<template>
  <LoadingRights>
    <div class="fixed h-screen w-full bg-white">
      <header class="fixed top-0 z-30 w-full desktop:h-14">
        <AppHeader />
      </header>
      <main class="container mx-auto relative pt-14 h-full">
        <slot />
        <div v-if="hasPermission('view_meetingparticipant', meetingId)" class="fixed bottom-5 right-5 z-50">
          <sl-tooltip content="Créer des observations multiple et rapide">
            <sgdf-circle-button @click="onClick" name="note-sticky"></sgdf-circle-button>
          </sl-tooltip>
        </div>
      </main>
    </div>
  </LoadingRights>
</template>

<script setup lang="ts">
import "@sgdf/ui-library/sgdf-circle-button.js";

const meetingId = useMeetingId();
const hasPermission = usePermission();

async function onClick() {
  if (meetingId) {
    await navigateTo(RouteUtils.getMeetingNotes(meetingId.value!));
  }
}
</script>
<style>
@media (min-width: 1700px) {
  .container {
    max-width: 1700px;
  }
}
</style>
